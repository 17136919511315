@charset "UTF-8";
body {
  overflow-x: hidden !important;
}

.pri:hover {
  color: #665cd7 !important;
}

.wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  perspective: 1000;
}

.section {
  height: 100%;
  width: calc( 100% / 5 );
  float: left;
  position: relative;
}
.sections {
  width: 500%;
  height: 100%;
}
.section__title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
  color: #fff;
}


.carousel-innerr > .carousel-item {
  transition: transform 1.2s ease-in-out !important;
}

.scroll_styles::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

.scroll_styles::-webkit-scrollbar-thumb {
  background-color: transparent;
}

#pre-loader {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-size: 150% 150%;
  position: fixed;
  top: 0;
  background-color: #ffffff;
  z-index: 99999999;
  align-items: center;
  justify-content: center;
}

/* Menu Header */
.header_mobile {
  border-bottom: solid 1px rgba(25, 34, 79, 0.08);
  background: rgba(0, 0, 0, 0.9);
  overflow: hidden;
  height: 60px;
  width: 100%;
  z-index: 1;
  position: fixed;
  transition: all 0.4s ease-out, background 1s ease-out;
}

.header_mobile.menu-open {
  height: 100%;
  background: #fff;
  transition: all 0.45s ease-out, background 0.8s ease-out;
}

/* Menu List items */
.contact_btn {
  color: #665CD7;
  border-color: #665CD7;
}

.mobile-menu {
  background-color: #665CD7;
  height: 100%;
  text-align: center;
  clear: both;
}

.header_mobile ul.menu {
  position: relative;
  display: block;
  background-color: #fff;
  padding: 40px 0px 30px;
  list-style: none;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.sub-menu {
  display: none;
  background-color: #665cd7;
  border-bottom: 1px solid #717176;
  text-decoration: none;
  font-size: 18px;
  line-height: 2.4;
  width: 100%;
}
.sub-menu a {
  width: 100%;
  color: white;
}

.header_mobile ul.menu li.menu-item a {
  display: block;
  position: relative;
  color: #000;
  text-decoration: none;
  font-size: 18px;
  line-height: 2.8;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
}

.header_mobile ul.menu li.menu-item {
  opacity: 0;
  transition: transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99), opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99);
}

.header_mobile ul.menu li.active {
  background-color: rgba(100, 85, 194, 0.05);
  color: #665CD7;
  opacity: 0;
  transition: transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99), opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99);
}
.header_mobile ul.menu li.active a {
  color: #665CD7;
}

.header_mobile ul.menu li.menu-item:nth-child(1) {
  transition-delay: 0.35s;
}

.header_mobile ul.menu li.menu-item:nth-child(2) {
  transition-delay: 0.3s;
}

.header_mobile ul.menu li.menu-item:nth-child(3) {
  transition-delay: 0.25s;
}

.header_mobile ul.menu li.menu-item:nth-child(4) {
  transition-delay: 0.2s;
}

.header_mobile ul.menu li.menu-item:nth-child(5) {
  transition-delay: 0.15s;
}

.header_mobile ul.menu li.menu-item:nth-child(6) {
  transition-delay: 0.1s;
}

.header_mobile ul.menu li.menu-item:nth-child(7) {
  transition-delay: 0.05s;
}

.header_mobile.menu-open ul.menu li.menu-item {
  opacity: 1;
}

.header_mobile.menu-open ul.menu li.menu-item:nth-child(1) {
  transition-delay: 0.05s;
}

.header_mobile.menu-open ul.menu li.menu-item:nth-child(2) {
  transition-delay: 0.1s;
}

.header_mobile.menu-open ul.menu li.menu-item:nth-child(3) {
  transition-delay: 0.15s;
}

.header_mobile.menu-open ul.menu li.menu-item:nth-child(4) {
  transition-delay: 0.2s;
}

.header_mobile.menu-open ul.menu li.menu-item:nth-child(5) {
  transition-delay: 0.25s;
}

.header_mobile.menu-open ul.menu li.menu-item:nth-child(6) {
  transition-delay: 0.3s;
}

.header_mobile.menu-open ul.menu li.menu-item:nth-child(7) {
  transition-delay: 0.35s;
}

/* Menu Icon */
.crayond_logo_mobile {
  position: relative;
  display: flex;
  z-index: 2;
  /* Simply change property to float left to switch icon side :) */
  height: 60px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transform: rotate(0deg);
  transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
}

.icon-container {
  position: absolute;
  display: inline-block;
  z-index: 2;
  top: 0;
  right: 0;
  /* Simply change property to float left to switch icon side :) */
  height: 60px;
  width: 55px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transform: rotate(0deg);
  transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
}

.icon-container #menuicon {
  width: 20px;
  height: 10px;
  position: relative;
  display: block;
  margin: -4px auto 0;
  top: 50%;
}

#menuicon .bar {
  width: 100%;
  height: 1px;
  display: block;
  position: relative;
  background: #000;
  transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
}

#menuicon .bar.bar1 {
  transform: translateY(0px) rotate(0deg);
}

#menuicon .bar.bar2 {
  transform: translateY(6px) rotate(0deg);
}

.menu-open .icon-container {
  display: none;
  transform: rotate(90deg);
}

.menu-open .icon-container #menuicon .bar {
  transition: all 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  transition-delay: 0.1s;
}

.menu-open .icon-container #menuicon .bar.bar1 {
  transform: translateY(4px) rotate(45deg);
}

.menu-open .icon-container #menuicon .bar.bar2 {
  transform: translateY(3px) rotate(-45deg);
}

.floatingNav {
  background: #ffffff;
  border-radius: 2px;
  box-shadow: 0px 1px 10px #999 !important;
  padding: 5px 10px 0px 10px !important;
  transition: all 0.2s;
}
.floatingNav button {
  transition: all 0.2s;
  height: 36px !important;
  margin-bottom: 6px;
}

.fixed {
  background-color: red !important;
}

.nav-up {
  top: -107px !important;
  box-shadow: 0 7px 12px 0 rgba(16, 31, 108, 0.07);
}

.nav-down {
  box-shadow: 0 7px 12px 0 rgba(16, 31, 108, 0.07);
}

.header_mobile {
  background-color: #ffffff;
  position: fixed;
  z-index: 1000;
  width: 100%;
  top: 0;
  height: 60px;
}
.header_mobile tag {
  font-size: 10px;
  padding: 3px 4px;
  position: absolute;
  top: 0px;
  width: 69px;
  height: 17px;
  border-radius: 3px;
  background-color: #16cebc;
  line-height: 1.1;
  color: #fff;
  margin-left: 30px;
}
.header_mobile .logo_mobile {
  display: flex;
  padding: 0;
  height: 60px;
}
.header_mobile .logo_mobile a {
  margin: auto 0;
}
.header_mobile .logo_mobile a img {
  width: 120px;
  height: 100%;
}
.header_mobile .header_mobile_menu {
  display: flex;
  padding: 0;
  height: 60px;
}
.header_mobile .header_mobile_menu span {
  margin: auto 0 auto auto;
}
.header_mobile .header_mobile_menu span i {
  font-size: 20px;
}

.header {
  background-color: #ffffff;
  padding: 12px 10px 12px 10px;
  width: 100%;
  z-index: 12;
  position: fixed;
  top: 0;
  transition: top 0.5s ease-in-out;
  width: 100%;
}
.header .row {
  display: flex;
  flex-wrap: wrap;
}
.header .row a {
  float: left;
  color: #43434b;
  text-align: center;
  padding: 5px;
  text-decoration: none;
  font-size: 16px;
  line-height: 25px;
  border-radius: 4px;
}
.header .row .logo_area {
  display: flex;
  align-items: center;
}
.header .row .logo_area .logo {
  cursor: pointer;
  font-size: 25px;
  font-weight: bold;
}
.header .row .logo_area .logo img {
  width: 134px;
  height: 100%;
}
.header .row .header_menu {
  display: flex;
}
.header .row .header_menu span {
  color: #43434b;
  font-size: 1rem;
  margin: auto;
  padding: 10px 0px;
  cursor: pointer;
}
.header .row .header_menu span:hover {
  color: #665CD7;
}
.header .row .header_menu span a:hover {
  color: #665CD7;
}
.header .row .header_menu span tag {
  font-size: 10px;
  padding: 4px 6px;
  position: absolute;
  right: -20px;
  top: 0px;
  width: 69px;
  height: 19px;
  border-radius: 3px;
  background-color: #16cebc;
  line-height: 1.1;
  color: #fff;
}
.header .row .header_menu .active a {
  color: #665CD7;
  font-family: "fontLatoBold";
}
.header .row .sign_btn {
  display: flex;
  align-items: center;
}
.header .row .sign_btn button {
  margin: auto 0 auto auto;
  width: 160px;
  height: 46px;
  box-shadow: 0 7px 12px 0 rgba(16, 31, 108, 0.07);
  font-size: 16px;
  border-color: #665CD7;
  color: #665CD7;
  border-radius: 4px;
  background-color: #ffffff;
}
.header .row .sign_btn button:hover {
  color: #ffffff;
  background-color: #665CD7;
}
.header .row .sign_btn button:focus {
  outline: none;
  box-shadow: none;
}

.resources {
  position: relative;
}

a:hover {
  text-decoration: none;
}

.span-position {
  position: relative;
  transition-timing-function: ease-in;
}
.span-position:hover .dropdown {
  display: block;
}

svg {
  padding-top: 10px;
  height: 35px;
  width: 25px;
}

.dropdown {
  display: none;
  position: absolute;
  background-color: #fff;
  top: 50px;
  z-index: 13;
  left: -25px;
  width: 250px !important;
  border-radius: 4px;
  box-shadow: 0px 2px 7px 0px rgba(51, 74, 192, 0.12);
}
.dropdown #glossary {
  margin: 0;
  width: 100%;
  color: black;
  display: block;
  padding: 10px 20px;
  border-bottom: 1px solid rgba(108, 117, 125, 0.1215686275);
  text-align: left;
}
.dropdown #glossary:hover {
  color: #665CD7;
}
.dropdown #blogs {
  margin: 0;
  width: 100%;
  color: black;
  display: block;
  padding: 10px 20px;
  border-bottom: 1px solid rgba(108, 117, 125, 0.1215686275);
  text-align: left;
}
.dropdown #blogs:hover {
  color: #665CD7;
}
.dropdown #casestudy {
  margin: 0;
  width: 100%;
  color: black;
  display: block;
  padding: 10px 20px;
  font-size: 16px;
  border-bottom: 1px solid rgba(108, 117, 125, 0.1215686275);
  text-align: left;
}
.dropdown #casestudy:hover {
  color: #665CD7;
}
.dropdown #saasmetrics {
  margin: 0;
  width: 100%;
  color: black;
  display: block;
  padding: 10px 20px;
  font-size: 16px;
  border-bottom: 1px solid rgba(108, 117, 125, 0.1215686275);
  text-align: left;
}
.dropdown #saasmetrics:hover {
  color: #665CD7;
}

.bulletin {
  margin-bottom: 15px;
}

.header_text {
  position: relative;
  z-index: 1;
}

.transparent_header {
  background-color: transparent;
}
.transparent_header .row a {
  color: #ffffff;
}
.transparent_header .row .sign_btn button {
  border-color: #665CD7;
  color: #fff;
  background-color: #665CD7;
}

html {
  height: 100%;
  width: 100%;
  font-family: "fontLatoRegular";
}

body {
  height: 100%;
  width: 100%;
  font-family: "fontLatoRegular";
}

.first_section {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.video_player {
  position: fixed;
  bottom: 0;
  right: 0;
  height: auto;
  width: auto;
  min-height: 100%;
  min-width: 100%;
  z-index: -9999;
}
.video_player video {
  width: 100%;
  display: block;
}

.header_text {
  padding-top: 10%;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  padding-bottom: 6%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.banner_text_1 {
  font-size: 5.25rem;
  font-weight: bold;
}
.banner_text_1 p {
  color: #2b2a35;
}

.banner_text_2 {
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2;
  color: #43434b;
}

.Line-2 {
  width: 100%;
}

.banner_button {
  width: 300px;
  cursor: pointer;
  padding: 18px 30px;
  background-color: #665CD7;
  font-size: 16px;
  color: white;
  border: none;
  font-weight: 700;
  border-radius: 5px;
  position: relative;
  bottom: 0px;
}
.banner_button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}
.banner_button span i {
  position: absolute;
  opacity: 0;
  top: 4px;
  right: -20px;
  transition: 0.5s;
}
.banner_button:hover {
  background-color: #665CD7;
}
.banner_button:hover span {
  padding-right: 25px;
}
.banner_button:hover span i {
  opacity: 1;
  right: 0;
}
.banner_button:focus {
  outline: none;
}

.banner__button_div {
  padding-top: 30px;
}

.other_brand {
  height: 20vh;
  background-color: rgba(51, 74, 192, 0.03);
}
.other_brand .brands {
  height: 20vh;
  display: flex;
}
.other_brand .brands marquee {
  align-items: center;
  display: flex;
}
.other_brand .brands marquee img {
  margin: 30px;
}
.other_brand .brands .branding_list {
  width: 100%;
  display: flex;
}
.other_brand .brands .branding_list .brands_logos {
  margin: auto;
  width: 124px;
}

.who_you_are {
  background-color: #ffffff;
  margin-top: 6%;
  text-align: center;
  margin-bottom: 8%;
}
.who_you_are .text {
  margin-bottom: 1%;
}
.who_you_are .text h1 {
  color: #2b2a35;
  font-size: 2.6rem;
}
.who_you_are .text img {
  width: 316px;
}
.who_you_are .banner--text {
  font-size: 1.5rem;
  color: #383838;
}
.who_you_are .card_div {
  text-align: left;
}
.who_you_are .card_div .card-div {
  margin-top: 7%;
  transition: all 0.6s;
}
.who_you_are .card_div .card-div:hover {
  margin-top: 5.8%;
}
.who_you_are .card_div .card-- {
  cursor: context-menu;
  background-color: #FFE953;
  padding: 54px;
  border-radius: 9px;
  height: 375px;
}
.who_you_are .card_div .card-- .card--head {
  margin-top: 16px;
  font-weight: bold;
  color: #2b2a35;
}
.who_you_are .card_div .card-- .card--text {
  margin-bottom: 33px;
  margin-top: 10%;
  font-size: 1.13rem;
  font-weight: 400;
  line-height: 32px;
  color: #43434b;
}
.who_you_are .card_div .card-- .btnn {
  background-color: #000000;
  border-radius: 4px;
  color: #ffffff;
  font-size: 14px;
  padding: 12px 24px;
  font-weight: 700;
  outline: none;
  text-decoration: none;
  border: 1.5px solid #000000;
}
.who_you_are .card_div .card-- .btnn:hover {
  outline: none;
  background-color: #ffffff;
  border-radius: 4px;
  color: #000000;
  border: 1.5px solid #000000;
}
.who_you_are .card_div .card-- .btnn i {
  font-size: 1.2rem;
  font-weight: 500;
}
.who_you_are .card_div .card-- .Oval_idea img {
  width: 33px;
  height: 42px;
}
.who_you_are .card_div .card-- .Oval_product img {
  width: 42px;
  height: 31px;
}
.who_you_are .card_div .card-- .Oval_build img {
  width: 31px;
  height: 39px;
}
.who_you_are .card_div .card-- .fa_icons {
  display: flex;
  font-size: 28px;
  padding: 13px;
  background-color: white;
  border-radius: 50px;
  position: absolute;
  top: -45px;
  left: 65px;
  border: 0.5px solid rgba(0, 0, 0, 0.1803921569);
  box-shadow: 0 3px 1px 0 rgba(141, 99, 29, 0.1);
  height: 88px;
  text-align: center;
  width: 88px;
  vertical-align: middle;
}
.who_you_are .card_div .card-- .fa_icons i {
  margin: auto;
}
.who_you_are .card_div .card-- .fa_icons img {
  width: 43px;
  height: 43px;
  margin: auto;
}

.why_us h1 {
  font-size: 2.85rem;
  font-weight: 700;
}
.why_us .text {
  text-align: center;
  padding-top: 6%;
  font-size: 1.4rem;
}
.why_us .container_bg_section1 {
  background-color: rgba(51, 74, 192, 0.03);
}
.why_us .container_bg_section2 {
  background-color: #ffffff;
}
.why_us .banner--text {
  font-size: 1.5rem;
  color: #383838;
}
.why_us .row {
  padding-top: 8%;
  padding-bottom: 8%;
}
.why_us .row .text_para {
  line-height: 2.1;
  color: #595959;
  font-weight: 400;
  font-size: 1.25rem;
}
.why_us .row .btnn {
  color: #002ee5;
  padding: 0px;
  font-weight: 700;
  text-decoration: none;
}
.why_us .row .btnn i {
  font-size: 1.2rem;
  font-weight: 800;
}
.why_us .row .fa_icon {
  font-size: 28px;
  padding: 16px;
  background-color: #ff8f00;
  color: white;
  border-radius: 50px;
  height: 74px;
  text-align: center;
  width: 74px;
  vertical-align: middle;
  margin-bottom: 6%;
  display: flex;
}
.why_us .row .fa_icon img {
  width: 37px;
  height: 32px;
  margin: auto;
}
.why_us .row .flag {
  background-color: #ffffff;
  margin-bottom: 6%;
  margin-top: 6%;
  text-align: center;
  display: flex;
  height: 32px;
}
.why_us .row .flag img {
  position: absolute;
  width: 100px;
  height: 100%;
}
.why_us .row .flag p {
  color: #b08400;
  margin: auto 14px;
  z-index: 1;
  padding-top: 2px;
}
.why_us .row .horizontal_line {
  background-color: rgba(51, 74, 192, 0.25);
  width: 1px;
}
.why_us .row .section_content {
  min-height: 400px;
}
.why_us .row tapSection {
  margin: auto;
}
.why_us .row tapSection span {
  border-radius: 6px;
  padding: 14px 25px;
  cursor: pointer;
}
.why_us .row tapSection .active_sale {
  color: #fff;
  border-radius: 6px;
  background-color: #665cd7 !important;
}
.why_us .row tapSection .active_code {
  color: #fff;
  border-radius: 6px;
  background-color: #665cd7 !important;
}
.why_us .row tapSection .active_build {
  color: #fff;
  border-radius: 6px;
  background-color: #665cd7 !important;
}
.why_us .row tapSection .active_deploy {
  color: #fff;
  border-radius: 6px;
  background-color: #665cd7 !important;
}
.why_us .row tapSection .active_launch {
  color: #fff;
  border-radius: 6px;
  background-color: #665cd7 !important;
}
.why_us .row .coming_soon_email {
  margin-top: 50px;
  padding: 10px 16px;
  border: 2px solid;
  border-radius: 4px;
}
.why_us .row .coming_soon_email input {
  height: 48px;
  border: none;
  width: calc(100% - 156px);
  outline: none;
}
.why_us .row .coming_soon_email button {
  color: #fff;
  background-color: black;
  width: 152px;
  height: 48px;
  border: none;
  outline: none;
}
.why_us .row .coming_soon_email button:focus {
  outline: 0 !important;
  border: 0 !important;
  background-color: #242438;
}
.why_us .row .img_section img {
  width: 100%;
  height: 100%;
  padding: 6px;
}
.why_us .row .Mascot_img {
  background-position: center;
  background-size: cover;
  height: 479px;
}

.our_secrets {
  background-color: #F9F9FD;
  padding-bottom: 8%;
}
.our_secrets h1 {
  font-weight: 700;
  font-size: 2.85rem;
}
.our_secrets .text {
  text-align: center;
  padding-top: 6%;
  font-size: 1.4rem;
}
.our_secrets .row {
  background-repeat: no-repeat;
  background-position: center;
}
.our_secrets .banner--text {
  font-size: 1.5rem;
  color: #383838;
}
.our_secrets .card__body_div {
  padding: 14px 12px;
  margin-top: 66px;
}
.our_secrets .card__body_div .card {
  height: 112%;
  border-bottom-right-radius: 28px;
  border: none;
  box-shadow: 0 0.3px 1px 0 rgba(51, 74, 192, 0.12), 0 10px 20px 0 rgba(51, 74, 192, 0.12);
  border: solid 1px rgba(51, 74, 192, 0.12);
}
.our_secrets .card__body_div .card_primary {
  background-color: #665CD7;
}
.our_secrets .card__body_div .card_primary a {
  color: #ffffff;
  text-decoration: none;
}
.our_secrets .card__body_div .card-title {
  margin-top: 14%;
  margin-bottom: 6%;
}
.our_secrets .card__body_div .card__body {
  margin: auto;
  padding: 14px 30px 16px 30px;
}
.our_secrets .card__body_div .card__body p {
  font-size: 1.3rem;
}
.our_secrets .card__body_div .card__body .Oval_code {
  box-shadow: 0 3px 1px 0 rgba(55, 89, 93, 0.07);
  border: solid 1px rgba(51, 74, 192, 0.15);
}
.our_secrets .card__body_div .card__body .Oval_flash img {
  width: 22px;
  height: 33px;
}
.our_secrets .card__body_div .card__body .card-text {
  color: #595959;
  font-size: 18px;
  line-height: 33px;
}
.our_secrets .card__body_div .card__body .fa_icon {
  box-shadow: 0 3px 1px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px rgba(0, 0, 0, 0.09);
  font-size: 28px;
  display: flex;
  background-color: white;
  border-radius: 60px;
  position: absolute;
  top: -40px;
  left: 40px;
  height: 81px;
  text-align: center;
  width: 81px;
  vertical-align: middle;
}
.our_secrets .card__body_div .card__body .fa_icon img {
  margin: auto;
  width: 35px;
  display: flex;
  align-items: center;
}
.our_secrets .card__body_div h5 {
  font-weight: 700;
}

.crayond_ceo {
  background-color: #FFE953;
  padding: 6% 0;
}
.crayond_ceo .about-crayond {
  display: flex;
}
.crayond_ceo .about-crayond .row {
  margin: auto;
  position: relative;
}
.crayond_ceo .about-crayond .row span {
  color: #c7ac1f;
}
.crayond_ceo .about-crayond .row .details_of_crayond {
  margin-bottom: 30px;
  font-size: 2.3rem;
  font-weight: 300;
}
.crayond_ceo .about-crayond .row .details_of_crayond .text_bold {
  color: #2b2a35;
}
.crayond_ceo .about-crayond .row .details_of_crayond:before {
  content: "“";
  left: -15px;
  top: -15px;
  position: absolute;
  color: #000;
  font-weight: 600;
  font-size: 2.5rem;
}
.crayond_ceo .about-crayond .row .name_of_writer {
  width: 100%;
  font-size: 1.25rem;
}
.crayond_ceo .about-crayond .row img {
  width: 110px;
}
.crayond_ceo .images_ceo {
  display: flex;
}
.crayond_ceo .images_ceo img {
  margin: 0 0 0 auto;
  width: 400px;
  height: auto;
}

.quick_facts {
  padding: 75px 0px;
  background-color: whitesmoke;
}
.quick_facts .facts_head {
  color: #6455c2;
  font-size: 32px;
  text-align: center;
}
.quick_facts h3 {
  font-size: 42px;
  text-align: center;
}
.quick_facts h4 {
  font-size: 32px;
  font-weight: 500 !important;
  text-align: center;
}
.quick_facts .hr {
  width: 75px;
  margin: 25px auto;
  border-bottom: 1px solid #6455c2;
}
.quick_facts .facts-para {
  font-weight: 500;
  color: #595959;
  font-size: 18px;
  line-height: 40px;
  width: 50%;
  margin: auto;
}
.quick_facts .containers {
  width: 60%;
  margin: 25px auto;
  padding-top: 20px;
  padding-bottom: 10px;
  background-color: rgba(253, 253, 253, 0.6705882353);
  border-radius: 20px;
  box-shadow: 0px 15px;
}
.quick_facts .containers .card-subhead {
  font-size: 20px;
  color: #adadad;
}

.next_billion {
  background-color: #242438;
  text-align: center;
  position: relative;
}
.next_billion .ovel {
  position: absolute;
  right: 15.9%;
}
.next_billion .ovel-6 {
  position: absolute;
  top: 9.3%;
  right: 20.9%;
}
.next_billion .billion {
  padding-top: 6%;
  padding-bottom: 6%;
}
.next_billion .billion .titel_button {
  padding-bottom: 30px;
}
.next_billion .billion .titel_button img {
  width: 60px;
}
.next_billion .billion .titel_button .button {
  padding: 15px 30px;
  background-color: rgba(255, 255, 255, 0.1);
  font-size: 1rem;
  color: #ffffff;
  border: none;
  border-radius: 5px;
}
.next_billion .billion .titel_button .button:focus {
  outline: none;
}
.next_billion .billion h1 {
  color: #ffffff;
  font-size: 3.75rem;
}
.next_billion .billion p {
  color: #ffffff;
  font-size: 22px;
}
.next_billion .billion .button_div {
  padding-top: 30px;
}
.next_billion .billion .button_div .banner_button {
  cursor: pointer;
  padding: 18px 30px;
  background-color: #665CD7;
  font-size: 1rem;
  color: #ffffff;
  border: none;
  font-weight: 700;
  border-radius: 5px;
}
.next_billion .billion .button_div .banner_button:focus {
  outline: none;
}
.next_billion .path-3 {
  position: absolute;
  bottom: 15.5%;
  right: 28.5%;
}
.next_billion .path {
  position: absolute;
  left: 19.6%;
  bottom: 0;
}

.footer {
  padding-bottom: 4%;
  padding-top: 4%;
}
.footer .row {
  display: flex;
  flex-wrap: wrap;
}
.footer .row .footer_title {
  font-size: 24px;
  font-weight: 600;
  line-height: 34px;
  color: #2b2a35;
}
.footer .row .unbeaFooter {
  width: 144px;
  height: -moz-fit-content;
  height: fit-content;
}
.footer .row .get_started_button {
  width: 154px;
  cursor: pointer;
  padding: 8px 20px;
  color: #ffffff;
  font-size: 16px;
  background-color: #665CD7;
  border: 1px solid #665CD7;
  font-weight: 600;
  border-radius: 0.5rem;
  transition: 0.5s;
  margin: 12px 0 36px 0;
}
.footer .row .get_started_button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}
.footer .row .get_started_button span i {
  position: absolute;
  opacity: 0;
  top: 0.25rem;
  right: -1.25rem;
  transition: 0.5s;
}
.footer .row .get_started_button:hover {
  color: #665CD7;
  background-color: #ffffff;
  border: 1px solid #665CD7;
}
.footer .row .get_started_button:hover span {
  padding-right: 1.5625rem;
}
.footer .row .get_started_button:hover span i {
  opacity: 1;
  right: 0;
}
.footer .row .get_started_button:focus {
  outline: none;
}
@media screen and (max-width: 576px) {
  .footer .row .footer_title {
    font-size: 20px;
    line-height: 26px;
  }
}
.footer .row .logo_address img {
  margin-bottom: 20px;
}
.footer .row .logo_address p {
  color: #43434b;
  margin-bottom: 3px;
  font-size: 16px;
}
.footer .row .menu_list {
  text-align: left;
  margin: 0px;
}
.footer .row .menu_types {
  margin-top: 20px;
}
.footer .row .footer_menu {
  cursor: pointer;
  margin: 0;
  font-size: 18px;
  color: #43434b;
}
.footer .row .footer_menu:hover {
  color: #000;
}
.footer .row .footer_menu_1 {
  cursor: pointer;
  margin: 0;
  font-size: 14px;
  color: #43434b;
}
.footer .row .footer_menu_1:hover {
  color: #000;
}
.footer .row ul {
  padding: 0;
}
.footer .row ul li {
  padding-bottom: 10px;
  list-style-type: none;
}
.footer .row ul li span {
  color: #43434b;
  cursor: pointer;
}
.footer .row ul li span:hover {
  text-decoration: underline;
  font-weight: 500;
}

.footer_social_media {
  border-top: 1px solid rgba(25, 34, 79, 0.08);
  padding: 12px 0px;
}
.footer_social_media .row {
  display: flex;
  flex-wrap: wrap;
}
.footer_social_media .row .footer_list {
  padding: 15px 80px 15px 0px;
  margin: 0;
}
.footer_social_media .row .footer_list span {
  font-size: 1rem;
  color: #43434b;
  cursor: pointer;
}
.footer_social_media .row .footer_list span:hover {
  color: #665CD7;
}
.footer_social_media .row .footer_address {
  display: flex;
  align-items: center;
  padding-right: 0;
}
.footer_social_media .row .footer_address a {
  width: 100%;
}
.footer_social_media .row .footer_address p {
  text-align: center;
  margin: auto 0;
  width: 100%;
  border-right: 1px solid rgba(25, 34, 79, 0.08);
  font-size: 14px;
  color: #43434b;
}

.social_icon {
  width: 39px;
  height: 37px;
  margin: 10px 10px 10px 0px;
  border-radius: 5px;
  display: flex;
  cursor: pointer;
  border-radius: 12px;
  background-color: rgba(29, 46, 137, 0.05);
}
.social_icon:hover {
  border: solid 1px #d4e4e6;
  box-shadow: 0 3px 1px 0 rgba(55, 89, 93, 0.07);
  background-color: #ffffff;
}
.social_icon i {
  margin: auto;
  color: #665CD7;
}

.title_of_careerss {
  position: absolute;
  /* top: 110px; */
  text-align: center;
  bottom: 124px;
}

.our_mission_section {
  padding: 75px 15px;
}
.our_mission_section .our_mission_title {
  text-align: center;
}
.our_mission_section .our_mission_title .title_mobile h1 {
  color: #2b2a35;
}
.our_mission_section .our_mission_title .title_mobile .explore {
  background-color: #665CD7;
  color: #ffffff;
  margin-top: 26px;
}
.our_mission_section .our_mission_title h5 {
  font-size: 16px;
  color: #665CD7;
  font-weight: 500;
}
.our_mission_section .our_mission_title h2 {
  font-size: 4.2rem;
  font-weight: 700;
  color: #2b2a35;
}
.our_mission_section .our_mission_title p {
  color: #595959;
  font-size: 22px;
  line-height: 2;
  font-weight: normal;
}
.our_mission_section .carousel_menu {
  margin-top: 25px;
}
.our_mission_section .carousel_menu li {
  border-radius: 26.5px !important;
  box-shadow: 0 3px 0 0 rgba(51, 74, 192, 0.04);
  border: solid 1px rgba(42, 52, 105, 0.1) !important;
  background-color: #ffffff;
  padding: 10px 20px;
  color: #43434b;
  font-family: fontGraphikMedium !important;
  font-size: 13px;
  font-weight: normal !important;
}
.our_mission_section .carousel_menu .active {
  color: #ffffff;
  background-color: #665CD7;
}
.our_mission_section .carousel-inner .slider_img {
  padding-right: 5%;
}
.our_mission_section .carousel-inner h5 {
  margin-top: 30px;
  font-size: 16px;
  color: #665CD7;
  font-weight: 500;
  margin-bottom: -10px;
}
.our_mission_section .carousel-inner h1 {
  font-size: 4.2rem;
  font-weight: 700;
  color: #2b2a35;
  margin-bottom: 15px;
}
.our_mission_section .carousel-inner p {
  color: #595959;
  font-size: 20px;
  font-weight: normal;
  text-align: inherit;
  margin-top: 15px;
  line-height: 2;
}

.epic_works .epic_title {
  text-align: center;
}
.epic_works .epic_title h5 {
  font-size: 16px;
  color: #665CD7;
  font-weight: 500;
}
.epic_works .epic_title h2 {
  font-size: 4.2rem;
  font-weight: 700;
  color: #2b2a35;
}
.epic_works .epic_title p {
  color: #595959;
  font-size: 22px;
  font-weight: normal;
  line-height: 2;
}
.epic_works .event_section p {
  color: #595959;
  font-size: 24px;
  font-weight: normal;
}

.fun_gallery h5 {
  font-size: 18px;
}

.current_opening h1 {
  font-size: 4.2rem;
  font-weight: 700;
  color: #2b2a35;
  margin-bottom: 15px;
}
.current_opening .opening_section {
  padding: 6%;
}
.current_opening .opening_section .positions_card .opening_titles {
  font-size: 17px;
  color: #595959;
}
.current_opening .opening_section .positions_card button {
  background-color: #665CD7;
  color: #ffffff;
  font-family: fontLatoBlack;
}
.current_opening .contact {
  border-top: 1px solid #f0f0f0;
  padding: 35px;
}
.current_opening .contact .box_info {
  text-align: left;
  display: flex;
}
.current_opening .contact .box_info .image_box {
  margin: auto;
}
.current_opening .contact .box_info .image_box .life_icon {
  position: absolute;
  top: 14px;
  border: solid 1px rgba(223, 25, 25, 0.16);
  padding: 15px 10px;
  border-radius: 50px;
}
.current_opening .contact .box_info .image_box .life_text {
  padding-left: 78px;
  margin-bottom: 12px;
}
.current_opening .contact .box_info .image_box .life_text .discription_life {
  margin-bottom: 15px;
  color: #2b2a35;
  font-size: 20px;
}
.current_opening .contact .box_info .image_box .life_text span a {
  color: #665CD7 !important;
  font-size: 18px;
  padding: 10px 16px;
  border: 1px solid rgba(0, 0, 0, 0.075);
  border-radius: 4px;
}

.our_team {
  background-color: #F9F9FD;
  padding-bottom: 8%;
}
.our_team h1 {
  font-weight: 700;
  font-size: 4.2rem;
}
.our_team .text {
  text-align: center;
  padding-top: 6%;
  font-size: 1.4rem;
}
.our_team .row {
  background-repeat: no-repeat;
  background-position: center;
}
.our_team .banner--text {
  font-size: 1.5rem;
  color: #383838;
}
.our_team .card__body_div {
  padding: 14px 12px;
  margin-top: 66px;
}
.our_team .card__body_div .card {
  height: 112%;
  border-bottom-right-radius: 28px;
  border: none;
  box-shadow: 0 0.3px 1px 0 rgba(51, 74, 192, 0.12), 0 10px 20px 0 rgba(51, 74, 192, 0.12);
  border: solid 1px rgba(51, 74, 192, 0.12);
}
.our_team .card__body_div .card_primary {
  background-color: #665CD7;
}
.our_team .card__body_div .card_primary a {
  color: #ffffff;
  text-decoration: none;
}
.our_team .card__body_div .card-title {
  margin-top: 14%;
  margin-bottom: 6%;
}
.our_team .card__body_div .card__body {
  margin: auto;
  padding: 14px 30px 16px 30px;
  height: 280px;
}
.our_team .card__body_div .card__body p {
  font-size: 21px;
}
.our_team .card__body_div .card__body .Oval_code {
  box-shadow: 0 3px 1px 0 rgba(55, 89, 93, 0.07);
  border: solid 1px rgba(51, 74, 192, 0.15);
}
.our_team .card__body_div .card__body .Oval_flash img {
  width: 22px;
  height: 33px;
}
.our_team .card__body_div .card__body .card-text {
  color: #595959;
  font-size: 18px;
  line-height: 33px;
  height: 134px;
}
.our_team .card__body_div .card__body .fa_icon {
  box-shadow: 0 3px 1px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px rgba(0, 0, 0, 0.09);
  font-size: 28px;
  display: flex;
  background-color: white;
  border-radius: 60px;
  position: absolute;
  top: -30px;
  left: 40px;
  height: 81px;
  text-align: center;
  width: 81px;
  vertical-align: middle;
}
.our_team .card__body_div .card__body .fa_icon img {
  margin: auto;
  width: 35px;
  display: flex;
  align-items: center;
}
.our_team .card__body_div h5 {
  font-weight: 700;
}

.join_team {
  background-color: #242438;
  text-align: center;
  position: relative;
}
.join_team .ovel {
  position: absolute;
  right: 15.9%;
}
.join_team .ovel-6 {
  position: absolute;
  top: 9.3%;
  right: 20.9%;
}
.join_team .billion {
  padding-top: 6%;
  padding-bottom: 6%;
}
.join_team .billion .titel_button {
  padding-bottom: 30px;
}
.join_team .billion .titel_button .button {
  padding: 15px 30px;
  background-color: rgba(0, 0, 0, 0.08);
  font-size: 16px;
  color: #ffffff;
  border: none;
  border-radius: 5px;
}
.join_team .billion .titel_button .button:focus {
  outline: none;
}
.join_team .billion h1 {
  color: #ffffff;
  font-size: 60px;
}
.join_team .billion p {
  color: #ffffff;
  font-size: 22px;
}
.join_team .billion .button_div {
  padding-top: 30px;
}
.join_team .billion .button_div .banner_button {
  cursor: pointer;
  padding: 18px 30px;
  background-color: #665CD7;
  font-size: 16px;
  color: #ffffff;
  border: none;
  font-weight: 700;
  border-radius: 5px;
}
.join_team .billion .button_div .banner_button:focus {
  outline: none;
}
.join_team .path-3 {
  position: absolute;
  bottom: 15.5%;
  right: 28.5%;
}
.join_team .path {
  position: absolute;
  left: 19.6%;
  bottom: 0;
}

.title_section {
  height: 99vh;
  display: flex;
  text-align: center;
  padding-top: 106px;
}
.title_section .container {
  margin: auto;
}
.title_section .container .who_you_are_title p {
  margin-bottom: 0;
  color: white;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4705882353);
  font-size: 4rem;
}

.our_vis_mis {
  padding: 6% 0px;
}
.our_vis_mis .who_you_are_title_sort {
  padding: 6% 0 6% 0;
  background-color: #665CD7;
  text-align: center;
}
.our_vis_mis .who_you_are_title_sort p {
  font-size: 2.8rem;
}
.our_vis_mis .mobile_title {
  font-size: 2rem !important;
}
.our_vis_mis .vission {
  padding: 30px;
}
.our_vis_mis .vission .card {
  border-radius: 10px;
}
.our_vis_mis .vission .card .card_body {
  height: 430px;
  padding: 12% 16% 12% 12%;
}
.our_vis_mis .vission .card .card_body h1 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #2b2a35;
  margin-bottom: 36px;
}
.our_vis_mis .vission .card .card_body p {
  font-size: 22px;
  line-height: 2;
  color: #595959;
}
.our_vis_mis .mission {
  padding: 30px;
}
.our_vis_mis .mission .card {
  border-radius: 10px;
}
.our_vis_mis .mission .card .card_body {
  height: 430px;
  background-color: #665CD7;
  padding: 12% 16% 12% 12%;
  border-radius: 10px;
}
.our_vis_mis .mission .card .card_body h1 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 36px;
}
.our_vis_mis .mission .card .card_body p {
  font-size: 22px;
  line-height: 2;
  color: #ffffff;
}

.about_crayond {
  padding: 6% 0px 0px 0px;
  background-color: #fafafa;
}
.about_crayond .text {
  text-align: center;
}

.our_culture {
  padding: 6% 0px 3% 0px;
}
.our_culture h1 {
  font-weight: 700;
  font-size: 2.85rem;
  margin-bottom: 0;
}
.our_culture .text {
  text-align: center;
  padding-top: 3%;
  font-size: 1.4rem;
}
.our_culture .card_div img {
  width: 80%;
  margin: auto;
  height: 100%;
}

.our_team_who_we_are {
  padding: 0px 0px 6% 0px;
}
.our_team_who_we_are h1 {
  font-weight: 700;
  font-size: 2.85rem;
  margin-bottom: 0;
}
.our_team_who_we_are .text {
  text-align: center;
  font-size: 1.4rem;
}
.our_team_who_we_are .gallery_list {
  margin-top: 4%;
}
.our_team_who_we_are .gallery_list .musics {
  width: 100%;
  height: 100%;
}
.our_team_who_we_are .gallery_list .team1 {
  width: 100%;
  height: 260px;
  padding-bottom: 8px;
}
.our_team_who_we_are .gallery_list .team2 {
  padding-top: 8px;
  width: 100%;
  height: 260px;
}
.our_team_who_we_are .gallery_list .team3 {
  padding-top: 16px;
  width: 100%;
  height: 360px;
}

.sliders_time_line .time_line_list {
  display: flex;
  border-top: 1.5px solid #e9ecef;
}
.sliders_time_line .time_line_list .active {
  color: #665CD7 !important;
  font-weight: 600;
  border-top: 1.5px solid #665CD7 !important;
}
.sliders_time_line .time_line_list .time_line_years {
  color: #878787;
  cursor: pointer;
  padding: 10px 5px;
  margin: auto;
  border-bottom: 1.5px solid transparent;
}
.sliders_time_line .time_contantes {
  background-color: rgba(0, 0, 0, 0.02);
  padding: 2% 16px 6% 16px;
}
.sliders_time_line .time_contantes .slide .carousel-inner .carousel-item .slider_img {
  padding-right: 100px;
}
.sliders_time_line .time_contantes .slide .carousel-inner .carousel-item .slider_discription {
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 30px 46px;
  border-radius: 25px;
  border-bottom-right-radius: 110px;
  border-top-left-radius: 65px;
  margin: auto;
  height: 340px;
}
.sliders_time_line .time_contantes .slide .carousel-inner .carousel-item .slider_discription .date {
  font-size: 20px;
  color: #878787;
}
.sliders_time_line .time_contantes .slide .carousel-inner .carousel-item .slider_discription h1 {
  font-size: 2rem;
  color: #2b2a35;
  margin-bottom: 0;
}
.sliders_time_line .time_contantes .slide .carousel-inner .carousel-item .slider_discription .diccription {
  color: #595959;
  font-size: 20px;
  line-height: 46px;
}
.sliders_time_line .time_contantes .slide .arrows {
  text-align: center;
  z-index: 12;
}
.sliders_time_line .time_contantes .slide .arrows a {
  padding: 10px 12px;
  margin-right: 52px;
  border-radius: 20px;
  box-shadow: 0 3px 1px 0 rgba(51, 74, 192, 0.05);
  border: solid 1px #e3e3e3;
  background-color: #ffffff;
}
.sliders_time_line .time_contantes .slide .arrows a i {
  color: #383838;
}
.sliders_time_line .time_contantes .slide .arrows .right {
  margin-right: 0 !important;
  padding: 10px 14px;
}

.mobile_arrow_right {
  display: flex !important;
  padding: 13px 13px;
  border-radius: 50px;
  box-shadow: 0 3px 1px 0 rgba(51, 74, 192, 0.05);
  border: solid 1px #e3e3e3;
  background-color: #ffffff;
}
.mobile_arrow_right i {
  padding-left: 4px;
}

.mobile_arrow_left {
  display: flex !important;
  padding: 13px 13px;
  border-radius: 50px;
  box-shadow: 0 3px 1px 0 rgba(51, 74, 192, 0.05);
  border: solid 1px #e3e3e3;
  background-color: #ffffff;
}
.mobile_arrow_left i {
  padding-right: 5px;
}

.coming_soon {
  display: flex;
  align-items: center;
  height: 100vh;
}
.coming_soon .row {
  padding-top: 160px;
}

.our_approach_title {
  position: relative;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  text-align: center;
  height: 88vh;
  display: flex;
}
.our_approach_title .banner_flag p {
  border-radius: 4px;
  color: #fff;
  padding: 8px 14px;
  width: 317px;
  font-size: 16px;
  margin: auto;
  margin-bottom: 30px;
  background-image: linear-gradient(to left, #9e8eff, #23d9de);
}
.our_approach_title .title p {
  font-size: 58px;
  color: #222c5d;
  line-height: 1.31;
  margin-bottom: 30px;
}
.our_approach_title .sub_text p {
  font-size: 24px;
  color: #595959;
}
.our_approach_title .move_next_section {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.our_approach_title .move_next_section img {
  width: 21px;
  cursor: pointer;
}
.our_approach_title .move_next_section .bounce {
  animation: bounce 2s infinite;
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.tap_fixed {
  position: fixed;
  height: auto !important;
  opacity: 1 !important;
  width: 100%;
  background-color: #fff;
  z-index: 1000 !important;
  top: 0;
  transition: all 0.5s;
  display: block !important;
  box-shadow: 0px 3px 19px rgba(0, 0, 0, 0.1215686275);
}
.tap_fixed .list_of_team {
  padding: 0px !important;
}
.tap_fixed .active_section_tap {
  border-bottom: 3px solid #665cd7;
}

.product_planing .list_of_team {
  padding: 2% 0px;
  color: #595959;
}
.product_planing .list_of_team .nav_menu {
  margin: 0px 21px;
  width: 69px;
  padding: 14px;
}
.product_planing .list_of_team .team_icon {
  z-index: 1;
  width: 69px;
  height: 69px;
  border-radius: 35.5px;
  box-shadow: 0 12px 16px 0 #f2f7ff;
  background-color: #ffffff;
  border: 1px solid #e6e6e7;
  margin: 10px 21px 10px 21px;
  display: flex;
  cursor: pointer;
}
.product_planing .list_of_team .team_icon img {
  font-size: 24px;
  margin: auto;
}
.product_planing .list_of_team .active_section_tap .team_icon {
  border: 3px solid #fff !important;
  background-color: #665cd7 !important;
}
.product_planing .list_of_team .active_section_tap p {
  color: #665cd7 !important;
  font-weight: 800;
}
.product_planing .list_of_team .time_line {
  width: 460px;
  position: absolute;
  top: 43%;
  height: 2px;
  background-color: #e6e6e7;
}
.product_planing .list_of_team .time_line:after {
  background: #47c9a2;
  width: 100px;
}

.sticky_tap {
  position: fixed;
  background-color: white;
  z-index: 1000;
  transition: all 0.5s;
  top: 5vh;
  border-radius: 70px;
  box-shadow: 4px 1px 18px 0px rgba(0, 0, 0, 0.0901960784);
  margin-left: -7%;
  width: 76px;
  padding: 0;
  overflow: hidden;
}
.sticky_tap:hover {
  opacity: 1 !important;
}
.sticky_tap .container {
  margin: auto;
  padding: 4px 10px 26px 10px;
}
.sticky_tap .list_of_team {
  display: flow-root !important;
  justify-content: center;
  padding: 2% 0px;
}
.sticky_tap .list_of_team .nav_menu {
  margin: 0px 21px;
  width: 69px;
  padding: 14px;
}
.sticky_tap .list_of_team .team_icon {
  z-index: 1;
  width: 55px;
  height: 55px;
  border-radius: 35.5px;
  box-shadow: 0 12px 16px 0 #f2f7ff;
  background-color: #ffffff;
  border: 1px solid #e6e6e7;
  margin: 14px 0px 3px 0px;
  display: flex;
  cursor: pointer;
}
.sticky_tap .list_of_team .team_icon img {
  margin: auto;
  width: 20px;
}
.sticky_tap .list_of_team .team_icon svg {
  width: 20px;
  margin: auto;
}
.sticky_tap .list_of_team .team_icon svg path {
  opacity: 0.496;
}
.sticky_tap .list_of_team .active_section_tap .team_icon {
  border: 3px solid #fff !important;
  background-color: #665cd7 !important;
}
.sticky_tap .list_of_team .active_section_tap p {
  color: #000 !important;
  font-weight: 800;
}
.sticky_tap .list_of_team .active_section_tap svg {
  width: 20px;
  margin: auto;
}
.sticky_tap .list_of_team .active_section_tap svg path {
  fill: #fff;
  opacity: 1;
}
.sticky_tap .list_of_team .time_line {
  display: none;
  position: absolute;
  top: 40px;
  height: 380px;
  background-color: #e6e6e7;
  width: 2px;
  left: 26px;
  z-index: -1;
}

@media only screen and (min-width: 1440px) {
  .header_text {
    background-size: cover;
    margin-bottom: 4%;
    padding-bottom: 6%;
  }
}
@media only screen and (min-width: 2240px) {
  .header_text {
    background-size: contain;
    margin-bottom: 4%;
    padding-bottom: 6%;
  }
  .title_section .container {
    margin: auto;
  }
  .title_section .container .who_you_are_title p {
    margin-bottom: 0;
    color: white;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4705882353);
    font-size: 4.8rem;
  }
}
@media only screen and (min-width: 1200px) {
  .header_text {
    padding-top: 10%;
    padding-bottom: 10%;
  }
  .contact_footer_menu {
    width: 16% !important;
  }
  .sign_btn {
    display: contents !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header_text {
    background-size: contain;
    margin-bottom: 4%;
    padding-bottom: 6%;
  }
  .who_you_are .card_div .card-div .card-- .card--head {
    font-size: 1.4rem;
  }
  .who_you_are .card_div .card-div .card-- .card--text {
    font-size: 1.15rem;
  }
  .our_mission_section .carousel-inner p {
    font-size: 16px;
  }
  .epic_works .epic_title p {
    font-size: 20px;
  }
  .current_opening .opening_section .positions_card {
    padding: 0px 15px;
  }
  .current_opening .opening_section .positions_card button {
    width: 150px;
  }
  .our_vis_mis .vission .card .card_body {
    padding: 12% 15% 12% 12%;
  }
  .our_vis_mis .mission .card .card_body {
    padding: 12% 15% 12% 12%;
  }
  .time_contantes .slide .carousel-inner .carousel-item .slider_img {
    padding-right: 26px !important;
  }
  .our_approach_title {
    height: 100vh;
  }
  .why_us .project_planing h1 {
    font-size: 2.35rem;
  }
}
@media only screen and (min-width: 767px) and (max-width: 991px) {
  .header_mobile {
    display: block !important;
  }
  .header_mobile .header {
    display: none !important;
    position: fixed;
    top: 0;
    border-bottom: 1px solid #f1f1f1;
    padding: 10px;
  }
  .header_mobile .header_menu {
    display: none !important;
  }
  .header_mobile .sign_btn {
    display: none !important;
  }
  .header_mobile .whe_we_are_video_player {
    top: 60px !important;
  }
  .header_mobile .header_text {
    top: 65px;
  }
  .header_mobile .header_text .banner_text_1 {
    font-size: 3.25rem;
  }
  .header_mobile .header_text .banner_text_2 {
    font-size: 1.2rem;
  }
  .header_mobile .other_brand {
    height: auto;
  }
  .header_mobile .other_brand .brands {
    height: auto;
  }
  .header_mobile .other_brand .brands .branding_list img {
    margin: 25px auto !important;
  }
  .header_mobile .other_brand .brands .branding_list .img1 {
    width: 120px !important;
  }
  .header_mobile .other_brand .brands .branding_list .img2 {
    width: 100px !important;
  }
  .header_mobile .other_brand .brands .branding_list .img3 {
    width: 75px !important;
  }
  .header_mobile .other_brand .brands .branding_list .img4 {
    width: 120px !important;
  }
  .header_mobile .other_brand .brands .branding_list .img5 {
    width: 110px !important;
  }
  .header_mobile .who_you_are .text h1 {
    font-size: 2rem;
  }
  .header_mobile .who_you_are .text .banner--text {
    font-size: 1.2rem;
  }
  .header_mobile .who_you_are .card_div .card-div {
    transition: none;
    margin-top: 8% !important;
  }
  .header_mobile .who_you_are .card_div .card-div:hover {
    margin-top: 8% !important;
  }
  .header_mobile .who_you_are .card_div .card-div .card-- {
    padding: 25px !important;
  }
  .header_mobile .who_you_are .card_div .card-div .card-- .card--head {
    font-size: 1.2rem;
  }
  .header_mobile .who_you_are .card_div .card-div .card-- .card--text {
    font-size: 1rem;
  }
  .header_mobile .who_you_are .card_div .card-div .card-- .fa_icons {
    top: -30px;
    left: 44px;
    height: 60px;
    width: 60px;
  }
  .header_mobile .who_you_are .card_div .card-div .card-- .fa_icons img {
    width: 25px;
    height: 30px;
  }
  .header_mobile .why_us .text h1 {
    font-size: 2rem;
  }
  .header_mobile .why_us .text .banner--text {
    font-size: 1.2rem;
  }
  .header_mobile .why_us .row .icon_div .fa_icon {
    background-color: green;
    height: 60px;
    width: 60px;
  }
  .header_mobile .why_us .row .icon_div .fa_icon img {
    width: 28px;
    height: 30px;
  }
  .header_mobile .why_us .row .icon_div .flag {
    background-color: #ffffff;
    margin-bottom: 6%;
    text-align: center;
    display: flex;
  }
  .header_mobile .why_us .row .icon_div .flag img {
    width: 100px;
    height: 100%;
  }
  .header_mobile .why_us .row h1 {
    font-size: 1.7rem;
  }
  .header_mobile .why_us .row .text_para {
    font-size: 1rem;
  }
  .header_mobile .why_us .row .coming_soon_email input {
    width: 100%;
  }
  .header_mobile .why_us .row .coming_soon_email .desktop_btn {
    display: none;
  }
  .header_mobile .why_us .row .coming_soon_email .mobile_btn {
    width: 100% !important;
    display: block !important;
  }
  .header_mobile .our_secrets .text h1 {
    font-size: 2rem;
  }
  .header_mobile .our_secrets .banner--text {
    font-size: 1.2rem;
  }
  .header_mobile .our_secrets .row .card__body_div .card .card__body {
    padding: 14px 20px 16px 20px;
  }
  .header_mobile .our_secrets .row .card__body_div .card .card__body .fa_icon {
    top: -32px;
    padding: 0;
    height: 60px;
    width: 60px;
    display: flex;
  }
  .header_mobile .our_secrets .row .card__body_div .card .card__body .fa_icon img {
    width: 25px;
    margin: auto;
  }
  .header_mobile .our_secrets .row .card__body_div .card .card__body .card-title {
    margin-top: 8%;
  }
  .header_mobile .our_secrets .row .card__body_div .card .card__body .card-text {
    font-size: 1rem;
  }
  .header_mobile .crayond_ceo .row .about-crayond .details_of_crayond {
    font-size: 1.7rem;
  }
  .header_mobile .crayond_ceo .row .images_ceo img {
    width: 100%;
  }
  .header_mobile .next_billion .billion h1 {
    font-size: 2.5rem;
  }
  .header_mobile .next_billion .billion p {
    font-size: 19px;
  }
  .header_mobile .next_billion .path-3 {
    right: 9.5%;
    display: none !important;
  }
  .header_mobile .next_billion .ovel-6 {
    display: none !important;
  }
  .header_mobile .footer_social_media .row .footer_list {
    padding: 15px 20px 15px 0px;
  }
  .header_mobile .our_mission_section {
    padding: 45px 15px 0px 15px;
  }
  .header_mobile .our_mission_section .our_mission_title .title_mobile {
    margin-bottom: 60px !important;
  }
  .header_mobile .our_mission_section .our_mission_title h2 {
    font-size: 32px;
  }
  .header_mobile .our_mission_section .our_mission_title .mission_discription {
    font-size: 19px;
  }
  .header_mobile .our_mission_section .slide .carousel-inner .slider_img {
    padding-right: 15px;
  }
  .header_mobile .our_mission_section .slide .carousel-inner h5 {
    margin-bottom: 4px;
    margin-top: 4px;
  }
  .header_mobile .our_mission_section .slide .carousel-inner h1 {
    margin-top: 6px;
    font-size: 22px;
    margin-bottom: 4px;
  }
  .header_mobile .our_mission_section .slide .carousel-inner p {
    font-size: 16px;
    margin-top: 7px;
    line-height: 1.6;
  }
  .header_mobile .our_mission_section .slide .carousel_menu {
    margin-bottom: 40px;
  }
  .header_mobile .our_mission_section .carousel-indicators li {
    margin-right: 8px !important;
  }
  .header_mobile .epic_works .container {
    padding: 60px 15px !important;
  }
  .header_mobile .epic_works .container .epic_title h2 {
    font-size: 32px;
  }
  .header_mobile .epic_works .container .epic_title p {
    font-size: 19px;
  }
  .header_mobile .fun_gallery .ambience_card_mobile {
    padding: 60px 15px 60px 15px !important;
  }
  .header_mobile .fun_gallery .ambience_card_mobile img {
    padding: 20px 0px 20px 0px !important;
  }
  .header_mobile .our_team h1 {
    font-size: 32px;
  }
  .header_mobile .our_team .card__body_div {
    height: 320px;
  }
  .header_mobile .our_team .card__body_div .card__body .card-text {
    font-size: 16px;
  }
  .header_mobile .current_opening h1 {
    font-size: 32px;
  }
  .header_mobile .current_opening .contact .box_info .image_box .life_text .discription_life {
    font-size: 17px;
  }
  .header_mobile .join_team .billion h1 {
    font-size: 40px;
  }
  .header_mobile .join_team .billion p {
    font-size: 19px;
  }
  .header_mobile .join_team .path-3 {
    display: none;
  }
  .header_mobile .join_team .ovel-6 {
    display: none;
  }
  .header_mobile .title_section {
    padding-top: 0;
  }
  .header_mobile .title_section .container .who_you_are_title p {
    font-size: 3rem;
  }
  .header_mobile .our_vis_mis {
    padding: 0;
  }
  .header_mobile .our_vis_mis .who_you_are_title_sort {
    margin-top: 60px;
  }
  .header_mobile .our_vis_mis .vission .card img {
    width: 8% !important;
  }
  .header_mobile .our_vis_mis .vission .card .card_body {
    padding: 12% 12% 12% 12%;
    height: 460px;
  }
  .header_mobile .our_vis_mis .vission .card .card_body h1 {
    font-size: 2rem;
  }
  .header_mobile .our_vis_mis .vission .card .card_body p {
    font-size: 1.2rem;
  }
  .header_mobile .our_vis_mis .mission .card .card_body {
    padding: 12% 12% 12% 12%;
    height: 460px;
  }
  .header_mobile .our_vis_mis .mission .card .card_body h1 {
    font-size: 2rem;
  }
  .header_mobile .our_vis_mis .mission .card .card_body p {
    font-size: 1.2rem;
  }
  .header_mobile .our_culture .text h1 {
    font-size: 2rem;
  }
  .header_mobile .our_culture p {
    font-size: 1.2rem;
  }
  .header_mobile .about_crayond .text h1 {
    font-size: 2rem;
  }
  .header_mobile .about_crayond .text p {
    font-size: 1.2rem;
  }
  .header_mobile .our_team_who_we_are .text h1 {
    font-size: 2rem;
  }
  .header_mobile .time_contantes .slide .carousel-inner .carousel-item .slider_img {
    padding-right: 20px !important;
  }
  .header_mobile .time_contantes .slide .carousel-inner .carousel-item .slider_discription .date {
    font-size: 18px !important;
  }
  .header_mobile .time_contantes .slide .carousel-inner .carousel-item .slider_discription .titel {
    font-size: 22px !important;
  }
  .header_mobile .time_contantes .slide .carousel-inner .carousel-item .slider_discription .diccription {
    font-size: 16px !important;
    line-height: 1.5 !important;
  }
  .header_mobile .coming_soon {
    padding-top: 55px;
    height: auto !important;
  }
  .header_mobile .coming_soon .row {
    padding-top: 160px;
  }
  .header_mobile .email_error {
    top: 14px !important;
  }
  .header_mobile .our_approach_title {
    height: auto;
    padding-top: 16vh;
    padding-bottom: 14vh;
  }
  .header_mobile .our_approach_title .title p {
    font-size: 30px;
  }
  .header_mobile .our_approach_title .sub_text p {
    font-size: 19px;
  }
  .header_mobile .product_planing {
    padding: 40px 0px;
  }
  .header_mobile .product_planing .list_of_team .team_icon {
    width: 50px;
    height: 50px;
    border-radius: 30.5px;
    margin: auto;
  }
  .header_mobile .product_planing .list_of_team .team_icon i {
    font-size: 19px;
  }
  .header_mobile .product_planing .list_of_team .team_icon img {
    width: 18px;
  }
  .header_mobile .product_planing .list_of_team .time_line {
    width: 285px;
    position: absolute;
    top: 40%;
    height: 2px;
    background-color: #e6e6e7;
  }
  .header_mobile .product_planing .list_of_team p {
    font-size: 13px;
  }
  .header_mobile .product_planing .section_tap {
    margin: 0px 12px;
  }
  .header_mobile #tap-menu {
    display: none !important;
  }
  .header_mobile .why_us .project_planing {
    min-height: auto !important;
  }
  .header_mobile .why_us .project_planing .row {
    padding-bottom: 10% !important;
    padding-top: 10% !important;
  }
  .header_mobile .why_us .project_planing .row .section_content {
    min-height: 340px;
  }
  .header_mobile .why_us .project_planing .horizontal_line {
    display: none;
  }
  .header_mobile .why_us .project_planing .mobile_tap_section {
    padding: 0px !important;
    overflow: auto;
    display: flex;
    padding: 6px 5px 6px 5px !important;
    background-color: transparent !important;
    border: none !important;
    margin: 0 !important;
  }
  .header_mobile .why_us .project_planing .mobile_tap_section tapSection {
    height: 100% !important;
    display: inline-flex;
    border: none !important;
    padding: 0px !important;
    padding: 0px 10px !important;
  }
  .header_mobile .why_us .project_planing .mobile_tap_section tapSection .active_code {
    border-radius: 50px;
  }
  .header_mobile .why_us .project_planing .mobile_tap_section tapSection .space_in_word {
    width: 110px;
  }
  .header_mobile .why_us .project_planing .mobile_tap_section tapSection span {
    font-size: 13px;
    padding: 4px 14px;
    background-color: rgba(0, 0, 0, 0.08);
    border-radius: 50px;
  }
  .header_mobile .sticky_tap {
    display: none !important;
  }
}
@media only screen and (max-width: 768px) {
  .header_text .banner_text_1 {
    font-size: 2rem !important;
  }
  .facts-para {
    font-size: 18px !important;
    width: 80% !important;
    margin: auto;
  }
}
@media only screen and (max-width: 767px) {
  body {
    overflow-x: hidden !important;
  }
  .header_mobile {
    display: block !important;
  }
  .header_mobile tag {
    font-size: 10px;
    padding: 3px 4px;
    position: absolute;
    top: 135px;
    width: 65px;
    height: 17px;
    border-radius: 3px;
    background-color: #16cebc;
    line-height: 1.1;
    color: #fff;
    margin-left: 30px;
  }
  .header {
    display: none;
    position: fixed;
    top: 0;
    border-bottom: 1px solid #f1f1f1;
    padding: 10px;
  }
  .header_menu {
    display: none !important;
  }
  .sign_btn {
    display: none !important;
  }
  .whe_we_are_video_player {
    top: 60px !important;
  }
  .video_player video {
    position: absolute;
    top: 60px;
  }
  .title_section {
    height: 240px;
  }
  .mobile_title {
    display: block;
  }
  .who_you_are_title {
    display: none;
  }
  .header_text {
    background-image: none;
    position: unset !important;
    margin-top: 15%;
    padding-bottom: 90px;
    padding-top: 20%;
    display: flex;
    height: unset;
    background-size: contain;
  }
  .banner_text_1 {
    font-size: 3.25rem;
  }
  .banner_text_2 {
    font-size: 1.2rem;
  }
  marquee {
    display: block !important;
  }
  .other_brand {
    height: auto !important;
  }
  .other_brand .brands {
    height: auto !important;
  }
  .other_brand .brands .branding_list {
    display: none;
  }
  .who_you_are {
    margin-top: 15%;
    margin-bottom: 10%;
  }
  .who_you_are .text h1 {
    font-size: 2.3rem;
    margin-bottom: 0;
  }
  .who_you_are .text img {
    width: 255px;
  }
  .who_you_are .text .banner--text {
    font-size: 1.1rem;
  }
  .who_you_are .card_div .card-div {
    padding: 15px 30px;
    transition: none;
    margin-top: 15%;
    margin-bottom: 4%;
  }
  .who_you_are .card_div .card-div:hover {
    margin-top: 15%;
  }
  .who_you_are .card_div .card-div .card-- {
    padding: 54px 30px;
  }
  .who_you_are .card_div .card-div .card-- .fa_icons {
    top: -35px;
  }
  .who_you_are .card_div h2 {
    font-size: 1.5rem;
  }
  .why_us .container_bg_section1 {
    text-align: center;
  }
  .why_us .container_bg_section2 {
    text-align: center;
  }
  .why_us .text {
    padding-top: 15%;
  }
  .why_us .text h1 {
    font-size: 2.3rem;
    margin-bottom: 0;
  }
  .why_us .text img {
    width: 255px;
  }
  .why_us .text .banner--text {
    font-size: 1.1rem;
    margin-bottom: 0;
  }
  .why_us h1 {
    font-size: 1.5rem;
  }
  .why_us .row {
    padding-top: 18%;
    padding-bottom: 15%;
  }
  .why_us .row .text_para {
    font-size: 1.1rem;
  }
  .why_us .row .fa_icon {
    display: none;
  }
  .why_us .row .left_line {
    display: none;
  }
  .why_us .row .center_line {
    display: unset !important;
  }
  .why_us .row .mobile_responce_img {
    display: block !important;
  }
  .why_us .row .desktop_responce_img {
    display: none;
  }
  .why_us .row .coming_soon_email {
    padding: 0px 15px;
  }
  .why_us .row .coming_soon_email input {
    width: 100%;
  }
  .why_us .row .coming_soon_email .desktop_btn {
    display: none;
  }
  .why_us .row .coming_soon_email .mobile_btn {
    width: 100% !important;
    display: block !important;
  }
  .our_secrets {
    padding-bottom: 15%;
  }
  .our_secrets .text {
    padding-top: 15%;
  }
  .our_secrets .text h1 {
    font-size: 2.3rem;
    margin-bottom: 0;
  }
  .our_secrets .text img {
    width: 255px;
  }
  .our_secrets .text .banner--text {
    font-size: 1.1rem;
    margin-bottom: 0;
  }
  .our_secrets .row .card__body_div {
    padding: 14px 30px;
  }
  .crayond_ceo {
    padding: 10% 0;
    margin: 8% 0px;
  }
  .crayond_ceo .about-crayond {
    padding: 20px;
  }
  .crayond_ceo .about-crayond .row .details_of_crayond {
    font-size: 1.5rem;
  }
  .crayond_ceo .about-crayond .row .name_of_writer {
    font-size: 1rem;
  }
  .crayond_ceo .images_ceo_mobile {
    padding: 15px;
    display: flex !important;
  }
  .crayond_ceo .images_ceo_mobile .row_mobile {
    margin: auto !important;
  }
  .crayond_ceo .images_ceo_mobile .row_mobile img {
    width: calc(100vw - 60px);
  }
  .crayond_ceo .images_ceo {
    display: none !important;
  }
  .next_billion .billion {
    padding-top: 12%;
    padding-bottom: 12%;
  }
  .next_billion .billion h1 {
    font-size: 2.5rem;
  }
  .next_billion .billion p {
    font-size: 18px;
  }
  .next_billion .path-3 {
    right: 9.5%;
    display: none;
  }
  .next_billion .ovel-6 {
    display: none;
  }
  .next_billion .path {
    left: 10.6%;
  }
  .footer {
    padding-bottom: 10%;
    padding-top: 25%;
  }
  .footer .logo_address {
    padding-bottom: 16px;
  }
  .footer .logo_address img {
    margin-bottom: 16px;
  }
  .footer .menu_list {
    text-align: left !important;
    margin-bottom: 14px !important;
  }
  .footer_social_media {
    padding: 25px 0px;
  }
  .footer_social_media .tp .footer_list {
    padding: 15px 0px;
    margin: auto;
  }
  .footer_social_media .mobile_social_links {
    margin-bottom: 30px;
    display: flex !important;
  }
  .footer_social_media .footer_address {
    padding-bottom: 5px;
  }
  .footer_social_media .footer_address p {
    text-align: left !important;
    margin-top: 0 !important;
  }
  .social_icon_link {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .footer_social_media1 {
    display: none !important;
  }
  .our_mission_section {
    padding: 46px 15px;
  }
  .our_mission_section .our_mission_title .title_mobile {
    margin-bottom: 60px !important;
  }
  .our_mission_section .our_mission_title .title_mobile h1 {
    font-size: 32px;
    line-height: 1.4;
  }
  .our_mission_section .our_mission_title h2 {
    font-size: 34px;
  }
  .our_mission_section .our_mission_title .mission_discription {
    font-size: 18px;
  }
  .our_mission_section .carousel-inner .slider_img {
    padding-right: 15px;
  }
  .our_mission_section .carousel-inner h5 {
    margin-top: 45px;
  }
  .our_mission_section .carousel-inner h1 {
    font-size: 24px;
  }
  .our_mission_section .carousel-inner p {
    font-size: 17px;
  }
  .epic_works .container {
    padding: 65px 15px !important;
  }
  .epic_works .container .epic_title h2 {
    font-size: 31px;
  }
  .epic_works .container .epic_title p {
    font-size: 18px;
  }
  .fun_gallery .ambience_card_mobile {
    padding: 55px 15px 55px 15px !important;
  }
  .fun_gallery .ambience_card_mobile img {
    padding: 20px 0px 20px 0px !important;
  }
  .our_team {
    padding: 6% 30px;
    margin-bottom: 40px;
  }
  .our_team h1 {
    font-size: 31px;
  }
  .current_opening h1 {
    font-size: 31px;
  }
  .current_opening .opening_section {
    padding: 6% 0px;
  }
  .current_opening .opening_section .positions_card button {
    width: 120px !important;
    padding: 8px !important;
    font-size: 13px;
  }
  .current_opening .opening_section .positions_card button:hover span {
    padding-right: 13px !important;
  }
  .current_opening .opening_section .positions_card button span i {
    top: 6px !important;
  }
  .current_opening .contact {
    padding: 35px 16px;
  }
  .current_opening .contact .box_info .image_box .life_text .discription_life {
    font-size: 16px;
  }
  .join_team .billion {
    padding-top: 16%;
    padding-bottom: 16%;
  }
  .join_team .billion h1 {
    font-size: 40px;
  }
  .join_team .billion p {
    font-size: 18px;
  }
  .join_team .ovel-6 {
    display: none;
  }
  .join_team .path-3 {
    display: none;
  }
  .join_team .button_div .banner_button {
    width: 265px !important;
  }
  .our_vis_mis {
    padding: 0;
  }
  .our_vis_mis .mobile_title {
    text-align: center;
    display: block !important;
  }
  .our_vis_mis .who_you_are_title_sort {
    margin-top: 60px;
  }
  .our_vis_mis .vission .card img {
    width: 8%;
  }
  .our_vis_mis .vission .card .card_body {
    height: 472px;
    padding: 12% 12% 12% 12%;
  }
  .our_vis_mis .vission .card .card_body h1 {
    font-size: 2.1rem;
  }
  .our_vis_mis .vission .card .card_body p {
    font-size: 1.2rem;
  }
  .our_vis_mis .mission .card .card_body {
    height: 472px;
    padding: 12% 12% 12% 12%;
  }
  .our_vis_mis .mission .card .card_body h1 {
    font-size: 2.1rem;
  }
  .our_vis_mis .mission .card .card_body p {
    font-size: 1.2rem;
  }
  .about_crayond {
    padding: 8% 0px 0px 0px !important;
  }
  .about_crayond .text h1 {
    font-size: 2.1rem;
  }
  .our_culture .text h1 {
    font-size: 2.1rem;
  }
  .our_culture .text p {
    font-size: 1.2rem;
  }
  .our_culture .card_div img {
    width: 100%;
    height: 100%;
  }
  .time_contantes {
    padding: 5% 16px 15% 16px !important;
  }
  .time_contantes .container {
    padding: 0;
  }
  .time_contantes .arrowslist {
    position: absolute;
    bottom: 15px;
  }
  .time_contantes .slide .carousel-inner {
    margin-bottom: 0px !important;
  }
  .time_contantes .slide .carousel-inner .carousel-item {
    padding: 0px 15px;
  }
  .time_contantes .slide .carousel-inner .carousel-item .slider_img {
    padding-right: 16px !important;
    margin-bottom: 20px;
  }
  .time_contantes .slide .carousel-inner .carousel-item .slider_discription {
    min-height: 340px !important;
    padding: 30px !important;
    height: unset !important;
  }
  .time_contantes .slide .carousel-inner .carousel-item .slider_discription .date {
    font-size: 16px !important;
  }
  .time_contantes .slide .carousel-inner .carousel-item .slider_discription .titel {
    font-size: 26px !important;
  }
  .time_contantes .slide .carousel-inner .carousel-item .slider_discription .diccription {
    font-size: 16px !important;
    line-height: 2 !important;
  }
  .time_contantes .slide .arrows {
    display: none !important;
    position: absolute;
    top: 420px;
    width: 100%;
    z-index: 12;
  }
  .time_contantes .slide .arrows a {
    padding: 10px 12px;
    margin-right: 52px;
    border-radius: 20px;
    box-shadow: 0 3px 1px 0 rgba(51, 74, 192, 0.05);
    border: solid 1px #e3e3e3;
    background-color: #ffffff;
  }
  .time_contantes .slide .arrows a i {
    color: #383838;
  }
  .time_contantes .slide .arrows .right {
    padding: 10px 14px;
    margin-right: 0 !important;
  }
  .our_team_who_we_are {
    padding: 0px 0px 10% 0px;
  }
  .our_team_who_we_are .text h1 {
    font-size: 2.1rem;
  }
  .divider_line {
    width: 255px;
  }
  .banner_button {
    width: 265px !important;
  }
  .carousel-control-prev {
    display: flex !important;
  }
  .carousel-control-next {
    display: flex !important;
  }
  .mobile_arrow_right {
    display: flex !important;
    padding: 13px 13px;
    border-radius: 50px;
    box-shadow: 0 3px 1px 0 rgba(51, 74, 192, 0.05);
    border: solid 1px #e3e3e3;
    background-color: #ffffff;
  }
  .mobile_arrow_right i {
    padding-left: 4px;
  }
  .mobile_arrow_left {
    display: flex !important;
    padding: 13px 13px;
    border-radius: 50px;
    box-shadow: 0 3px 1px 0 rgba(51, 74, 192, 0.05);
    border: solid 1px #e3e3e3;
    background-color: #ffffff;
  }
  .mobile_arrow_left i {
    padding-right: 5px;
  }
  .typeform-widget {
    height: 520px !important;
  }
  .coming_soon {
    padding-top: 55px;
    height: auto !important;
  }
  .coming_soon .flag_bg {
    display: flex;
  }
  .coming_soon .flag {
    text-align: center;
    margin: auto;
  }
  .coming_soon .flag img {
    display: none;
  }
  .coming_soon .flag p {
    background-color: #FFD34F;
    padding: 4px 20px 1px 20px;
    border-radius: 50px;
    padding-top: 4px !important;
    margin: auto;
  }
  .email_error {
    top: 14px !important;
  }
  .our_approach_title {
    height: 80vh;
  }
  .our_approach_title .banner_flag p {
    padding: 8px 0px;
    width: 255px;
    font-size: 14px;
  }
  .our_approach_title .title p {
    font-size: 30px;
  }
  .our_approach_title .sub_text p {
    font-size: 19px;
  }
  .product_planing {
    padding: 40px 0px;
  }
  .product_planing .list_of_team .team_icon {
    width: 50px;
    height: 50px;
    border-radius: 30.5px;
    margin: auto;
  }
  .product_planing .list_of_team .team_icon i {
    font-size: 19px;
  }
  .product_planing .list_of_team .team_icon img {
    width: 18px;
  }
  .product_planing .list_of_team .time_line {
    width: 285px;
    position: absolute;
    top: 40%;
    height: 2px;
    background-color: #e6e6e7;
  }
  .product_planing .list_of_team p {
    font-size: 13px;
  }
  .product_planing .section_tap {
    margin: auto;
  }
  #tap-menu {
    display: none !important;
  }
  .why_us .project_planing {
    min-height: auto !important;
  }
  .why_us .project_planing h1 {
    line-height: 1.5 !important;
  }
  .why_us .project_planing .row {
    padding-bottom: 15% !important;
    padding-top: 10% !important;
  }
  .why_us .project_planing .row .section_content {
    min-height: 340px;
  }
  .why_us .project_planing .horizontal_line {
    display: none;
  }
  .why_us .project_planing .mobile_tap_section {
    padding: 0px !important;
    overflow: auto;
    display: flex;
    padding: 6px 5px 6px 5px !important;
    background-color: transparent !important;
    border: none !important;
    margin: 0 !important;
  }
  .why_us .project_planing .mobile_tap_section tapSection {
    height: 100% !important;
    display: inline-flex;
    border: none !important;
    padding: 0px !important;
    padding: 0px 10px !important;
  }
  .why_us .project_planing .mobile_tap_section tapSection .active_code {
    border-radius: 50px;
  }
  .why_us .project_planing .mobile_tap_section tapSection .space_in_word {
    width: 110px;
  }
  .why_us .project_planing .mobile_tap_section tapSection span {
    font-size: 13px;
    padding: 4px 14px;
    background-color: rgba(0, 0, 0, 0.08);
    border-radius: 50px;
  }
  .why_us .project_planing .text_para {
    line-height: 1.8 !important;
  }
  .why_us .project_planing .flag img {
    display: none;
  }
  .why_us .project_planing .flag p {
    background-color: rgba(102, 92, 215, 0.1);
    padding: 4px 20px 1px 20px;
    border-radius: 50px;
    margin: auto;
  }
  .sticky_tap {
    display: none !important;
  }
}
@media only screen and (max-width: 321px) {
  .footer_social_media1 {
    display: none;
  }
  .why_us .project_planing {
    min-height: auto !important;
  }
  .why_us .project_planing h1 {
    line-height: 1.5 !important;
  }
  .why_us .project_planing .row {
    padding-bottom: 15% !important;
    padding-top: 10% !important;
  }
  .why_us .project_planing .row .section_content {
    min-height: 400px;
  }
}
.pri:hover {
  color: #665cd7;
}

.modal .modal-confirm {
  color: #434e65;
  width: 525px;
}
.modal .modal-confirm .modal-content {
  padding: 20px;
  font-size: 16px;
  border-radius: 5px;
  border: none;
}
.modal .modal-confirm .modal-content .modal-header {
  background: #47c9a2;
  border-bottom: none;
  position: relative;
  text-align: center;
  margin: -20px -20px 0;
  border-radius: 5px 5px 0 0;
  padding: 35px;
}
.modal .modal-confirm .modal-content h4 {
  text-align: center;
  font-size: 36px;
  margin: 10px 0;
}
.modal .modal-confirm .modal-content .form-control {
  min-height: 40px;
  border-radius: 3px;
}
.modal .modal-confirm .modal-content .btn {
  min-height: 40px;
  border-radius: 3px;
}
.modal .modal-confirm .modal-content .close {
  position: absolute;
  top: 15px;
  right: 15px;
  color: #fff;
  text-shadow: none;
  opacity: 0.5;
}
.modal .modal-confirm .modal-content .close:hover {
  opacity: 0.8;
}
.modal .modal-confirm .modal-content .icon-box {
  color: #fff;
  width: 95px;
  height: 95px;
  display: inline-block;
  border-radius: 50%;
  z-index: 9;
  border: 5px solid #fff;
  padding: 15px;
  text-align: center;
  margin: auto;
}
.modal .modal-confirm .modal-content .icon-box i {
  font-size: 64px;
  margin: -4px 0 0 -4px;
}
.modal .modal-confirm .modal-content .modal-dialog {
  margin-top: 100px;
}
.modal .modal-confirm .modal-content .btn {
  color: #fff;
  border-radius: 4px;
  background: #eeb711;
  text-decoration: none;
  transition: all 0.4s;
  line-height: normal;
  border-radius: 30px;
  margin-top: 10px;
  padding: 6px 20px;
  border: none;
}
.modal .modal-confirm .modal-content .btn:hover {
  outline: none;
  background: #eda645;
}
.modal .modal-confirm .modal-content .btn .btn:focus {
  background: #eda645;
  outline: none;
}
.modal .modal-confirm .modal-content .btn span {
  margin: 1px 3px 0;
  float: left;
}
.modal .modal-confirm .modal-content .btn i {
  margin-left: 1px;
  font-size: 20px;
  float: right;
}
.modal .modal-confirm .modal-content .trigger-btn {
  display: inline-block;
  margin: 100px auto;
}

.social_icon_link {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

@media only screen and (max-width: 426px) {
  .facts-para {
    width: 80% !important;
    margin: auto;
  }
  .response-xs {
    display: flex !important;
    justify-content: flex-start;
    width: 100%;
    margin: auto;
  }
  .privacy-gdpr {
    display: none !important;
  }
  .privacy {
    padding-left: 0px !important;
    padding-right: 10px !important;
    width: 100% !important;
  }
  .gdpr {
    padding-left: 0px !important;
    padding-right: 0px !important;
    width: 40% !important;
  }
}
@media only screen and (min-width: 1100px) {
  .privacy p {
    padding-right: 30px !important;
  }
}
@media only screen and (max-width: 991px) and (min-width: 767px) {
  .header {
    display: none;
    position: fixed;
    top: 0;
    border-bottom: 1px solid #f1f1f1;
    padding: 10px;
  }
  .who_you_are .text h1 {
    font-size: 2rem;
  }
  .who_you_are .text .banner--text {
    font-size: 1.2rem;
  }
  .who_you_are .card_div .card-div {
    transition: none;
    margin-top: 8% !important;
  }
  .who_you_are .card_div .card-div:hover {
    margin-top: 8% !important;
  }
  .who_you_are .card_div .card-div .card-- {
    padding: 25px !important;
  }
  .who_you_are .card_div .card-div .card-- .card--head {
    font-size: 1.2rem;
  }
  .who_you_are .card_div .card-div .card-- .card--text {
    font-size: 1rem;
  }
  .who_you_are .card_div .card-div .card-- .fa_icons {
    top: -30px;
    left: 44px;
    height: 60px;
    width: 60px;
  }
  .who_you_are .card_div .card-div .card-- .fa_icons img {
    width: 25px;
    height: 30px;
  }
  .why_us .text h1 {
    font-size: 2rem;
  }
  .why_us .text .banner--text {
    font-size: 1.2rem;
  }
  .why_us .row .icon_div .fa_icon {
    background-color: green;
    height: 60px;
    width: 60px;
  }
  .why_us .row .icon_div .fa_icon img {
    width: 28px;
    height: 30px;
  }
  .why_us .row .icon_div .flag {
    background-color: #ffffff;
    margin-bottom: 6%;
    text-align: center;
    display: flex;
  }
  .why_us .row .icon_div .flag img {
    width: 100px;
    height: 100%;
  }
  .why_us .row h1 {
    font-size: 1.7rem;
  }
  .why_us .row .text_para {
    font-size: 1rem;
  }
  .why_us .row .coming_soon_email input {
    width: 100%;
  }
  .why_us .row .coming_soon_email .desktop_btn {
    display: none;
  }
  .why_us .row .coming_soon_email .mobile_btn {
    width: 100% !important;
    display: block !important;
  }
  .our_secrets .text h1 {
    font-size: 2rem;
  }
  .our_secrets .banner--text {
    font-size: 1.2rem;
  }
  .our_secrets .row .card__body_div .card .card__body {
    padding: 14px 20px 16px 20px;
  }
  .our_secrets .row .card__body_div .card .card__body .fa_icon {
    top: -32px;
    padding: 0;
    height: 60px;
    width: 60px;
    display: flex;
  }
  .our_secrets .row .card__body_div .card .card__body .fa_icon img {
    width: 25px;
    margin: auto;
  }
  .our_secrets .row .card__body_div .card .card__body .card-title {
    margin-top: 8%;
  }
  .our_secrets .row .card__body_div .card .card__body .card-text {
    font-size: 1rem;
  }
  .crayond_ceo .row .about-crayond .details_of_crayond {
    font-size: 1.7rem;
  }
  .crayond_ceo .row .images_ceo img {
    width: 100%;
  }
  .next_billion .billion h1 {
    font-size: 2.5rem;
  }
  .next_billion .billion p {
    font-size: 19px;
  }
  .next_billion .path-3 {
    right: 9.5%;
    display: none !important;
  }
  .next_billion .ovel-6 {
    display: none !important;
  }
  .footer_social_media .row .footer_list {
    padding: 15px 20px 15px 0px;
  }
}
.header_mobile.menu-open {
  height: 100%;
  background: #fff;
  transition: all 0.45s ease-out, background 0.8s ease-out;
}/*# sourceMappingURL=styles.css.map */



.body {
    width: unset;
  }
  
  .response-xs {
    display: none !important;
  }
  
  .privacy {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  
  .pri:hover {
    color: #665cd7 !important;
  }
  
  .pri1 {
    color: #665cd7 !important;
  }
  
  .card1 {
    width: 100% !important;
    height: 300px;
    background-color: #259e3d;
    border-radius: 0em;
  }
  .card1 .row {
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .card1 .row_privacy {
    color: white;
    font-size: 48px;
    justify-content: center;
    margin-top: 85px;
  }
  @media screen and (max-width: 603px) {
    .card1 .row_privacy {
      color: white;
      font-size: 24px;
      margin-top: 130px;
      justify-content: center !important;
    }
  }
  @media screen and (max-width: 1024px) and (min-width: 603px) {
    .card1 .row_privacy {
      color: white;
      font-size: 38px;
      margin-top: 110px;
      justify-content: center !important;
    }
  }
  .card1 .row_date {
    color: rgba(255, 255, 255, 0.6);
    margin-top: 10px;
    justify-content: center;
  }
  .card1 .row_prior {
    color: rgba(255, 255, 255, 0.6);
    margin-top: 10px;
    justify-content: center;
  }
  
  .container .head_row {
    margin-top: 6% !important;
  }
  
  .paragraph {
    line-height: 30px;
    margin-bottom: 23px;
    color: #595959;
  }
  
  .sub-paragraph {
    line-height: 30px;
    margin-bottom: 23px;
    color: #595959;
  }
  
  .sub-head {
    margin-bottom: 14px;
    color: #595959;
  }
  
  .class_1 .para1 {
    font-size: 15px;
    line-height: 24px;
    color: #787882;
    margin-bottom: 21px;
  }
  
  .head_theme .head_theme {
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
    font-family: acumin-pro, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
    letter-spacing: 0.27px;
    margin-bottom: 15px;
    margin-top: 0px;
    color: #212121;
  }
  .head_theme .cb-factor__item {
    width: 12px;
    height: 4px;
    border-radius: 1px;
    background-color: #6450f0;
    left: -10px;
    margin-top: 10px !important;
    margin-right: 12px;
  }
  
  .head_theme1 .head_theme1 {
    font-size: 17px;
    line-height: 32px;
    font-weight: 700;
    font-family: acumin-pro, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
    letter-spacing: 0.27px;
    margin-bottom: 10px;
    margin-top: 0;
    color: #212121;
  }
  
  .article .cb-factor__item {
    width: 12px;
    height: 4px;
    border-radius: 1px;
    background-color: #6450f0;
    left: -10px;
    margin-top: 10px !important;
    margin-right: 12px;
  }
  .article .heads {
    margin-top: 10px;
    margin-bottom: 25px;
    color: rgba(0, 0, 0, 0.54);
    font-size: 16px;
    font-weight: 750;
    display: flex;
    margin-left: 0px !important;
  }
  
  .right_side {
    position: relative !important;
    right: 0 !important;
  }
  
  .container {
    position: sticky;
    top: 90px;
  }
  
  .head_right {
    margin-left: 4px;
    font-size: 16px;
    font-weight: 700;
    color: #212121;
    margin-bottom: 2%;
  }
  
  .linksz {
    width: 100%;
    cursor: pointer;
    line-height: 40px;
    color: #595959;
    font-weight: 400;
    font-size: 16px;
    display: flex !important;
    margin-left: 2px;
  }
  
  @media screen and (max-width: 427px) {
    .response-xs {
      display: flex !important;
      justify-content: flex-start;
      width: 100%;
      margin: 0px auto;
    }
    .right_side {
      display: none !important;
    }
  }
  .footer_social_media1 {
    display: none;
  }
  
  @media screen and (max-width: 768px) {
    .privacy {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
    .pri:hover {
      color: #665cd7 !important;
    }
    .pri1 {
      color: #665cd7 !important;
    }
    .right_side {
      margin-left: 0px;
    }
  }
  .p {
    line-height: 30px;
    color: #595959;
    font-weight: 400;
    font-size: 16px;
  }
  
  .cb-factor__item_1 {
    width: 3px !important;
    height: 12px !important;
    background-color: #fff;
    left: -8px;
    top: 2px;
    margin-top: 13px !important;
    display: block;
    position: relative;
  }
  
  .sidePadding {
    color: #6450f0;
  }
  .sidePadding .cb-factor__item_1 {
    display: flex;
    background-color: #6450f0;
  }
  
  .sidePadding_test {
    color: #6450f0;
  }
  .sidePadding_test .cb-factor__item_1 {
    display: flex;
    background-color: #6450f0;
  }