.map-wrapper{
    height: 100%;
    width: 100%;
}
#map .gm-style-iw-c{
    /* padding:0 !important; */
    box-shadow: 0px 15px 50px #0000002E !important;
}
#map .gm-style-iw-d{
    overflow: auto !important;
}
#map .gm-ui-hover-effect{
    display: none !important;
}

#map .controls{
    right: 20px !important;
    bottom: 20px !important;
}
#map .fullscreen-control{
    right: 20px !important;
    top: 20px !important;
}

#map .icon-wrapper{
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
}

#map .mb-20{
    margin-bottom: 20px;
}
#map .mb-10{
    margin-bottom: 10px;
}
#map .gmnoprint{
    display: none !important;
}
#map a[href^="http://maps.google.com/maps"],
a[href^="https://maps.google.com/maps"],
a[href^="https://www.google.com/maps"] {
    display: none !important;
}

.is-fullscreen{
height:100vh !important;
}

/* .is-fullscreen #map{
height:calc(100vh - 67px) !important;
} */

.is-fullscreen .fullscreen-control{
    display: none;
}

.is-fullscreen .flexRow{
    display: flex;
    align-items: center;   
    padding:20px;
    background-color: #F1F3F7; 
}
.flexRow{
    display: none;  
}

#map .version{
    bottom: 20px !important;
}

#map .map-types{
    left: 20px !important;
    bottom: 20px !important;

}

@media only screen and (max-width: 600px) {
    #map .map-types{
        left: 20px !important;
        bottom: 70px !important;
    }
    #map .controls{
        right: 20px !important;
        bottom: 70px !important;
    }
}

#map .map-types .mapTypeflex{
    display: flex;
    align-items: center;
    gap: 10px;
}

#map .map-types .mapTypeCard{
    width:54px;
    height: 54px;
    border-radius: 6px;
    position: relative;
    cursor: pointer;
}

#map .map-types .mapTypeCard img{
    max-width: 100%;
    height: auto;
    object-fit: cover;
}

#map .map-types .absoluteDiv{
    position: absolute;
    inset: auto 0 0 0;
    background-color: #000000;
    opacity: 0.5;
    border-radius: 0px 0px 6px 6px;
    text-align: center;
    color: #FFFFFF;
    font-family:"crayond_bold";
    font-size: 8px;
}